import React, { Fragment } from "react";

function IntroWord({ word }) {
    function Tokenize({ char }) {
        return (
            <span>
        {char}
      </span>
        );
    }
    return (
        <span style={{ display: "inline-block" }}>
      {word.split("").map((letter, letterIndex) => (
          <Tokenize index={letterIndex} char={letter} key={letterIndex} />
      ))}
    </span>
    );
}

function TextWrap({ children }) {
    return (
        <div>
            <p className="intro-text">
                {children.split(" ").map((word, wordIndex) => (
                    <Fragment key={wordIndex}>
                        <IntroWord key={wordIndex} word={word} />
                        <span> </span>
                    </Fragment>
                ))}
            </p>
        </div>
    );
}

export default TextWrap;