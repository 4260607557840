import React from 'react';
import ReactPlayer from 'react-player/youtube';
import TextWrap from "./fancyText";
import SpotifyPlayer from 'react-spotify-player';

const multimedia = {
    dance_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Dance-At-Bougival.jpg/316px-Dance-At-Bougival.jpg",
    dance_wiki: "https://en.wikipedia.org/wiki/Dance_at_Bougival",
    diberkato_url: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSB9BgD1cjx2Lk71eE4ZZWjvgHhYdZsY1xfXw&usqp=CAU",
    diberkato_insta: "https://www.instagram.com/diberkato/",
    shv_url: "https://schrabhomevideo.com/",
    shv_logo: "https://m.media-amazon.com/images/M/MV5BZDQ4NDQxZjYtN2Q1Yi00OTM3LWEyNjMtMTlmZjZhYjZjYzk2XkEyXkFqcGdeQXVyMDEzMjU3Nw@@._V1_UX182_CR0,0,182,268_AL_.jpg",
    jfk_vid: "https://www.youtube.com/watch?v=K7y2xPucnAo",
    charlie_jpg: "https://www.dropbox.com/s/w7nbn7yvojx1m8l/charlie.jpg?raw=1",
    edlritchmoon_jpg: "https://www.dropbox.com/s/64bz2le1y61f8ar/eldritchmoon_jpg1.jpeg?raw=1",
    mtg_url: "https://magic.wizards.com/en/articles",
    eff_url: "https://www.eff.org/",
    eff_jpg: "https://www.dropbox.com/s/ltgmiliyi1trnj8/eff_jpg.jpeg?raw=1"
}

const eff = (
    <div style={{ display: "flex", displayDirection: "column", padding: "2%" }}>
        <a href={multimedia.eff_url}>
            <img src={multimedia.eff_jpg} title="A free/fair internet is pretty important." alt="should be eff logo, sorry bout it."/>
        </a>
    </div>
)

const charlie = (
    <div style={{ flexDirection: "column", padding: "2%" }} className="fancy-wrapper">
        <TextWrap>
            Mr. Charlie, he's the best cat around! :D
        </TextWrap>
        <img src={multimedia.charlie_jpg} title="aW1wb3J0IHJlcXVlc3RzCnJlcXVlc3RzLmdldCgnaHR0cHM6Ly9jYXRmYWN0Lm5pbmphL2ZhY3QnKS5qc29uKClbJ2ZhY3QnXQ==" alt="should be charlie, sorry bout it"/>
    </div>
)

const mtg = (
    <div style={{ flexDirection: "column", padding: "2%" }} className="fancy-wrapper">
        <a href={multimedia.mtg_url}>
            <img src={multimedia.edlritchmoon_jpg} title="MTG is a pretty fun game :)" alt="should be Emy here, sorry bout it."/>
        </a>
    </div>
)

const video = (
    <div style={{ flexDirection: "column" }} className="fancy-wrapper">
        <TextWrap>
            Brad Neely made a series of these weird, goofy comic videos a few years back and they're pretty great.
        </TextWrap>
        <ReactPlayer controls={true} style={{ outline: "1px solid lightgray" }} url={multimedia.jfk_vid} />
    </div>
)

const dance = (
    <div style={{ flexDirection: "column", display: "inline-flex", height: "95%", paddingBottom: "10px", padding: "2%" }} className="fancy-wrapper">
        <TextWrap>
            Dance at Bougival by Pierre-Auguste Renoir.
        </TextWrap>
        <a href={multimedia.dance_wiki}>
            <img src={multimedia.dance_url} title="I love impressionism" alt="should be Dance at Bougival here, sorry bout it."/>
        </a>
    </div>
)

const lornaScrab = (
    <div style={{ display: "flex", flexDirection: "column", height: "95%", padding: "2%" }} className="fancy-wrapper">
        <TextWrap>
            Lorna by Alvaro De Carvalho
        </TextWrap>
        <a href={multimedia.diberkato_insta}>
            <img src={multimedia.diberkato_url} title="Vaporwave type art is awesome" alt="should be Lorna here, sorry bout it."/>
        </a>
        <TextWrap>
            The comedy of Rob Schrab
        </TextWrap>
        <a href={multimedia.shv_url}>
            <img src={multimedia.shv_logo} title="Rob makes a lot of interesting things" alt="should be the SHV logo, sorry bout it."/>
        </a>
    </div>
)

const music = (
    <div style={{ flexDirection: "column", height: "95%", padding: "2%" }} className={"fancy-wrapper"}>
        <TextWrap>
            Things I've been listening to lately.
        </TextWrap>
        <SpotifyPlayer
            uri="spotify:playlist:37i9dQZF1E395uOsVI345A"
            size="large"
            view="list"
            theme="black"
            />
    </div>
)

export {
    multimedia,
    video,
    dance,
    lornaScrab,
    music,
    charlie,
    mtg,
    eff
}
