import React from 'react';
import './App.css';
import * as Media from './components/media';

function App() {
  return (
      <div className="App-body">
          <h1>
              A few of my favorite things.
          </h1>
          {hello}
          {lifeThings}
          {topRow}
          {vidPlayer}
      </div>
  );
}

const hello = (
    <div style={{ width: "90%" }}>
        <p>Thanks for taking the leap of clicking strange links on the interwebs. I'll try to reward that trust and bravery ;)</p>
    </div>
)

const lifeThings = (
    <div style={{ outline: "1px solid lightgray", width: "90%", outlineOffset: "10px", fontSize: "1.3rem" }}>
        <p>So a few life things first haha. I believe that we should all live and die being comfortable with who we are as people. We should be honest with ourselves and own our ideas and ideals.</p>
        <p>I don't know if we can ever escape statism. It seems like there's too many of us spread out. I'm interested in hearing your thoughts on that or other similar subjects. Maybe the best we can do is fight for some semblance of equality and balance.</p>
    </div>
)

const danceMtg = (
    <div style={{ display: "flex", flexDirection: "column" }}>
        {Media.dance}
        {Media.mtg}
    </div>
)

const musicCharlie = (
    <div style={{ display: "flex", flexDirection: "column" }}>
        {Media.music}
        {Media.charlie}
    </div>
)

const topRow = (
    <div style={{ display: "flex", flexDirection: "row" }}>
        {danceMtg}
        {musicCharlie}
        {Media.lornaScrab}
    </div>
)

const vidPlayer = (
    <div style={{ display: "flex", flexDirection: "row" }}>
        {Media.video}
        {Media.eff}
    </div>
)

export default App;
